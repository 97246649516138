export default class LazyLoadImages {
    constructor(images) {
        this.init(images);
    }

    init(images) {
        const imageObserver = new IntersectionObserver((entries, imgObserver) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    const lazyImage = entry.target;
                    lazyImage.src = lazyImage.dataset.src;
                    lazyImage.classList.remove("lazy");
                    imgObserver.unobserve(lazyImage);
                }
            })
        });
        const arr = document.querySelectorAll(images);
        arr.forEach((v) => {
            imageObserver.observe(v);
        })
    }
}
