import jQuery from "jquery";
import LazyLoadImages from './helpers/lazyload'
import According from './helpers/according';
import IMask from 'imask';
import bootstrap from "bootstrap";

document.addEventListener('DOMContentLoaded', () => {

    // Lazy Loading Init
    const lazyLoad = new LazyLoadImages('.lazy');

    // According Init
    const accordingSelector = document.querySelector('.faq-list'); // Родительский элемент
    const accordingHeader = '.faq-list__header'; // Заголовок
    const accordingBody = '.faq-list__body'; // Контент
    const accordingHeaderActiveClass = 'faq-list__header_active'; // Активный класс для заголовка
    const accordingBodyActiveClass = 'faq-list__body_active'; // Активный класс для контента
    if (accordingSelector) {
        const according = new According(
            accordingSelector,
            accordingHeader,
            accordingBody,
            accordingHeaderActiveClass,
            accordingBodyActiveClass
        );
    }

    // Phone Mask Init
    const phoneFields = document.querySelectorAll('.form-control_phone');
    const maskOptions = {
        mask: '+{7}(000)000-00-00'
    };

    phoneFields.forEach(field => {
        IMask(field, maskOptions);
    });

    // Mobile Menu Init
    const sidemenu = document.querySelector('.sidemenu');
    const mobileMenuTrigger = document.querySelector('.mobile-menu-trigger');
    const closeMobileMenuTrigger = document.querySelector('.close-btn');

    mobileMenuTrigger.addEventListener('click', () => {
        sidemenu.classList.add('sidemenu_active');
    });

    closeMobileMenuTrigger.addEventListener('click', () => {
        sidemenu.classList.remove('sidemenu_active');
    });

    //Reviews Slider Init
    const reviewsSliderSelector = document.querySelector('.reviews-row');

    if (reviewsSliderSelector) {
        var slider = tns({
            container: reviewsSliderSelector,
            items: 3,
            controls: false,
            navPosition: 'bottom',
            mouseDrag: true,
            gutter: 10,
            swipeAngle: false,
            responsive: {
                0: {
                    items: 1
                },
                640: {
                    items: 1
                },
                700: {
                    items: 2
                },
                900: {
                    items: 3,
                }
            }
        });
    }

});
