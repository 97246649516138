export default class According{

    constructor(node, acHeader, acBody, activeHeaderClass, activeBodyClass){
        this.according = [];
        this.activeItem = null;

        this.init(node, acHeader, acBody, activeHeaderClass, activeBodyClass);
        this.active(this.according[0]);
    }

    init(node, acHeader, acBody, activeHeaderClass, activeBodyClass){
        const header = node.querySelectorAll( acHeader );
        const content = node.querySelectorAll( acBody );

        for (let index = 0; index < header.length; index++) {

            this.registerTab(header[index], content[index], activeHeaderClass, activeBodyClass);

        }

    }

    registerTab(header, content, activeHeaderClass, activeBodyClass){
        const accordingItem = new AccordingItem(header, content, activeHeaderClass, activeBodyClass);
        accordingItem.onActivate(() => this.active(accordingItem));
        this.according.push(accordingItem);
    }

    active (tabItem) {
        if (this.activeTab) {
            this.activeTab.setActive(false);
        }

        this.activeTab = tabItem;
        this.activeTab.setActive(true);
    }

}

class AccordingItem{
    constructor(header, content, activeHeaderClass, activeBodyClass){
        this.header = header;
        this.content = content;
        this.headerActiveClass = activeHeaderClass;
        this.bodyActiveClass = activeBodyClass;
    }

    onActivate(action){
        this.header.addEventListener( 'click', () => action(this) );
    }

    setActive(value){
        this.header.classList.toggle(this.headerActiveClass, value);
        this.content.classList.toggle(this.bodyActiveClass, value);
    }

}
